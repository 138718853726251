import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhHans from 'vuetify/es5/locale/zh-Hans'
import en from 'vuetify/lib/locale/en'
Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en
  },
  zhHans: {
    ...require('vuetify/es5/locale/zh-Hans'),
    $vuetify: zhHans
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'zhHans',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zhHans',
  messages,
  silentTranslationWarn: true
})
