// import Cookies from 'js-cookie'

// const uToken = 'u_token'
const darkMode = 'dark_mode'
import { ACCESS_TOKEN, USER_NAME, USER_INFO, DEPARTS } from '@/store/mutation-types'
import Vue from 'vue'
// 获取Token
export function getToken() {
  return Vue.ls.get(ACCESS_TOKEN)
}

// 设置Token，1天,与后端同步
export function setToken(token) {
  return Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000)
}

// 删除Token
export function removeToken() {
  Vue.ls.set(ACCESS_TOKEN, '')
  Vue.ls.set(USER_NAME, '')
  Vue.ls.set(USER_INFO, '')
  Vue.ls.set(DEPARTS, '')
  return true
}

export function removeAll() {
  return Vue.ls.clear()
  // return Cookies.Cookies.removeAll()
}

export function setDarkMode(mode) {
  // return Cookies.set(darkMode, mode, {expires: 365})
  return Vue.ls.set(darkMode, mode, 365 * 24 * 60 * 60 * 1000)
}

export function getDarkMode() {
  const mode = Vue.ls.get(darkMode)
  return !(undefined === mode || mode === false || mode === null)
  // return !(undefined === Cookies.get(darkMode) || 'false' === Cookies.get(darkMode));
}
