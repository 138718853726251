// const getters = {
//   token: state => state.user.token,   // token
//   user: state => state.user.user,     // 用户对象
// }
// export default getters

import Vue from 'vue'
import { USER_INFO, DEPARTS, ACCESS_TOKEN } from '@/store/mutation-types'
const getters = {
  token: state => {
    state.user.token = Vue.ls.get(ACCESS_TOKEN)
    return state.user.token
  },
  user: state => state.user,
  avatar: state => {
    const info = Vue.ls.get(USER_INFO)
    state.user.avatar = info ? info.avatar : ''
    return state.user.avatar
  },
  username: state => state.user.info.username,
  nickname: state => {
    const info = Vue.ls.get(USER_INFO)
    state.user.realname = info ? info.realname : ''
    return state.user.realname
  },
  welcome: state => state.user.welcome,
  permissionList: state => state.user.permissionList,
  userInfo: state => {
    const info = Vue.ls.get(USER_INFO)
    state.user.info = info || {}
    return state.user.info
  },
  departs: state => {
    const departs = Vue.ls.get(DEPARTS)
    state.user.departs = departs || {}
    return state.user.departs
  }
}

export default getters
