import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required
} from 'vee-validate/dist/rules'

extend('email', {
  ...email,
  message: '邮箱格式不正确'
})
extend('max', {
  ...max,
  message: '{_field_}最大长度{length}'
})
extend('min', {
  ...min,
  message: '{_field_}最小长度{length}'
})
extend('numeric', {
  ...numeric,
  message: '必须为数字'
})
extend('required', {
  ...required,
  message: '{_field_}为必填项'
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
