import router from './router'
import getPageTitle from '@/utils/get-page-title'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // 登录，跳转首页
      next({ path: '/' })
      NProgress.done()
    } else {
      const redirect = decodeURIComponent(from.query.redirect || to.path)
      if (to.fullPath === redirect) {
        next()
      } else {
        // 跳转到目的路由
        next({ path: redirect })
      }
    }
  } else if (to.fullPath === '/login' && from.fullPath !== '/register') { // 跳转到等页面时，成功之后重定向到之前页面
    if (to.path === from.path) {
      next()
    } else {
      from.fullPath !== '/forgetPassword' ? next({ path: '/login', query: { redirect: from.fullPath }}) : next({ path: '/login', query: { redirect: '/dashboard' }})
    }
  } else if (!to.meta.requireAuth) {
    next()
  } else {
    next({ path: '/login', query: { redirect: to.fullPath }})
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
