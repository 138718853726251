/** init domain config */
import Vue from 'vue'
// 设置全局API_BASE_URL
window._CONFIG = {}
Vue.prototype.API_BASE_URL = process.env.VUE_APP_SERVER_URL
Vue.prototype.VUE_APP_PREVIEW_URL = process.env.VUE_APP_PREVIEW_URL
Vue.prototype.VUE_APP_VIDEO_URL = process.env.VUE_APP_VIDEO_URL
window._CONFIG['domianURL'] = Vue.prototype.API_BASE_URL

window._CONFIG['onlinePreviewDomainURL'] = process.env.VUE_APP_PREVIEW_URL
window._CONFIG['staticDomainURL'] = Vue.prototype.API_BASE_URL + '/sys/common/static'
