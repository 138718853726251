
import Vue from 'vue'
import './config'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import '@/permission'
import Storage from 'vue-ls'
import config from '@/defaultSettings'
import VuetifyConfirm from '@/components/confirm'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
// bus通讯
import vueBus from '@/utils/vueBus'
Vue.use(vueBus)
// 组件路由守卫
import RouteMixin from '@/mixins/RouteMixin'
Vue.mixin(RouteMixin)
// 全局缓存
Vue.use(Storage, config.storageOptions)
Vue.config.productionTip = false
// Vue.use(vuetify)
Vue.use(VuetifyConfirm, { vuetify })
import message from 'vuetify-message-snackbar'
Vue.use(message)


export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
