import Vue from 'vue'
import { ACCESS_TOKEN, USER_NAME, USER_INFO, DEPARTS } from '@/store/mutation-types'
import { getUserInfo } from '@/api/auth/auth'
import { setToken, removeToken } from '@/utils/auth'
import { login, logout } from '@/api/login'
const state = {
  token: '', // token
  user: '', // 用户对象
  username: '',
  realname: '',
  tenantid: '',
  welcome: '',
  avatar: '',
  permissionList: [],
  info: {},
  departs: []
}

const mutations = {
  SET_TOKEN_STATE: (state, token) => {
    state.token = token
  },
  SET_USER_STATE: (state, user) => {
    state.user = user
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, { username, realname, welcome }) => {
    state.username = username
    state.realname = realname
    state.welcome = welcome
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_PERMISSIONLIST: (state, permissionList) => {
    state.permissionList = permissionList
  },
  SET_INFO: (state, info) => {
    state.info = info
  },
  SET_TENANT: (state, id) => {
    state.tenantid = id
  },
  SET_DEPARTS: (state, departs) => {
    state.departs = departs
  }
}

const actions = {
  // 用户登录
  login({ commit }, userInfo) {
    const { username, password, rememberMe, inputCode, checkKey, groupCompany } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, remember_me: rememberMe, captcha: inputCode, checkKey: checkKey, groupCompany })
        .then((response) => {
          // const { data } = response;
          if (response.success) {
            commit('SET_USER_STATE', response.result.userInfo)
            // commit("SET_TOKEN_STATE", response.result.token);
            const userInfo = response.result.userInfo
            // 保存登录用户信息
            Vue.ls.set(ACCESS_TOKEN, response.result.token, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(DEPARTS, response.result.departs, 7 * 24 * 60 * 60 * 1000)

            commit('SET_TOKEN', response.result.token)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname })
            commit('SET_AVATAR', userInfo.avatar)
            commit('SET_DEPARTS', response.result.departs)

            setToken(response.result.token)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((response) => {
          const { data } = response
          if (!data) {
            commit('SET_TOKEN_STATE', '')
            commit('SET_USER_STATE', '')
            commit('SET_INFO', {})
            commit('SET_NAME', { })
            commit('SET_AVATAR', '')
            commit('SET_DEPARTS', [])
            removeToken()
            resolve()
            reject('Verification failed, please Login again.')
          }
          commit('SET_USER_STATE', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 注销
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then((response) => {
          commit('SET_TOKEN_STATE', '')
          commit('SET_USER_STATE', '')
          commit('SET_INFO', {})
          commit('SET_NAME', {})
          commit('SET_AVATAR', '')
          commit('SET_DEPARTS', [])
          removeToken()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
