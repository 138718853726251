import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon, VAvatar, VProgressCircular } from 'vuetify/lib'

import i18n from '@/i18n'
import '@/sass/overrides.sass'
import 'font-awesome/css/font-awesome.min.css'
Vue.use(Vuetify)
Vue.component('v-snackbar', VSnackbar)
Vue.component('v-btn', VBtn)
Vue.component('v-icon', VIcon)
Vue.component('v-avatar', VAvatar)
Vue.component('v-progress-circular', VProgressCircular)
// VCard, VCardActions, VCardText, VDialog,VToolbar, VToolbarTitle, VSpacer
// Vue.component("v-card", VCard)
// Vue.component("v-card-actions", VCardActions)
// Vue.component("v-card-text", VCardText)
// Vue.component("v-dialog", VDialog)
// Vue.component("v-tool-bar", VToolbar)
// Vue.component("v-tool-bar-title", VToolbarTitle)
// Vue.component("v-spacer", VSpacer)
//
// const theme = {
//   primary: '#4CAF50',
//   secondary: '#9C27b0',
//   accent: '#9C27b0',
//   info: '#00CAE3'
// }

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      // dark: theme,
      // light: theme
    }
  },
  icons: {
    iconfont: 'fa4'
  }
})
