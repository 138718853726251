import axios from 'axios'
import _this from '../main.js'
import store from '@/store'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Router from '../router'
import { MessageBox } from 'element-ui'
// 1.创建axios实例
const service = axios.create({
  // 公共接口--这里注意后面会讲,url = base url + request url
  baseURL: process.env.VUE_APP_SERVER_URL,
  // baseURL: 'https://api.example.com',
  // 超时时间 单位是ms，这里设置了5s的超时时间
  timeout: 50 * 1000
})

// 2.请求拦截器request interceptor
service.interceptors.request.use(
  config => {
    // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

window.isTimeOut = false

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    // console.log('------异常响应------', data)
    // console.log('------异常响应------', path)
    switch (error.response.status) {
      case 403:
        _this.$message.error('拒绝访问')
        break
      case 500:

        if (!token && !window.isTimeOut) { // && data.message === 'Token失效，请重新登录' && path !== '/'
          window.isTimeOut = true
          MessageBox.confirm('请先登录', {
            title: '未登陆',
            icon: 'fa fa-exclamation',
            color: 'primary'
          }).then(res => {
            if (res) {
              Router.push('/login')
            }
            window.isTimeOut = false
          })
        } else if (token && data.message === 'Token失效，请重新登录' && !window.isTimeOut) {//isTimeOut控制弹窗已弹出  不再继续弹出
          window.isTimeOut = true
          MessageBox.confirm('登录已过期', {
            title: '很抱歉，登录已过期，请重新登录',
            icon: 'fa fa-exclamation',
            color: 'primary'
          }).then(res => {
            if (res) {
              store.dispatch('user/logout').then(() => {
                Router.push('/login')
              })
            }
            window.isTimeOut = false
          })
        }
        break
      case 404:
        _this.$message.error('很抱歉，资源未找到!')
        break
      case 504:
        _this.$message.error('网络超时')
        break
      case 401:
        _this.$message.error('未授权，请重新登录')
        break
      default:
        _this.$message.error(data.message)
        break
    }
  }
  return Promise.reject(error)
}

// 设置cross跨域 并设置访问权限 允许跨域携带cookie信息,使用JWT可关闭
service.defaults.withCredentials = false
service.interceptors.response.use((response) => {
  return response.data
}, err)
// service.interceptors.response.use(
//   // 接收到响应数据并成功后的一些共有的处理，关闭loading等
//   response => {
//     const res = response.data
//     // 如果自定义代码不是200，则将其判断为错误。
//     if (res.code !== 0 && res.code !== 200 ) {
//       // 50008: 非法Token; 50012: 异地登录; 50014: Token失效;
//       if (res.code === 401 || res.code === 50012 || res.code === 50014) {
//         // 重新登录
//         notification.confirm('会话失效，您可以留在当前页面，或重新登录', '权限不足', {
//           confirmButtonText: '确定',
//           cancelButtonText: '取消',
//           type: 'warning',
//           center: true
//         }).then(() => {
//           window.location.href = '#/login'
//         })
//       } else { // 其他异常直接提示
//         Message({
//           showClose: true,
//           message: '⚠' + res.message || 'Error',
//           type: 'error',
//           duration: 3 * 1000
//         })
//       }
//       return Promise.reject(new Error(res.message || 'Error'))
//     } else {
//       return res
//     }
//   },
//   error => {
//     /** *** 接收到异常响应的处理开始 *****/
//     // console.log('err' + error) // for debug
//     Message({
//       showClose: true,
//       message: error.message,
//       type: 'error',
//       duration: 5 * 1000
//     })
//     return Promise.reject(error)
//   }
// )

// export default service
export {
  service as axios
}
